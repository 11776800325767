
























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import { Inquiry } from './Index.vue';
import axios from 'axios';

@Component({
  components: {},
})
export default class Index extends Vue {
  @Prop(Object) inquiry!: Inquiry;
  private i18n = I18n;
  private btnDisabled = false;
  private loading = false;

  created(): void {
    if (!this.inquiry.company) this.$router.push(`/${this.i18n.language}/inquiry`);
  }

  private get phone() {
    return this.inquiry.phone1 || this.inquiry.phone2 || this.inquiry.phone3
      ? `${this.inquiry.phone1}-${this.inquiry.phone2}-${this.inquiry.phone3}`
      : '';
  }

  private async sendMail() {
    this.btnDisabled = true;
    setTimeout(() => {
      this.loading = true;
    }, 200);

    try {
      await fetch(process.env.VUE_APP_SENDMAIL_API_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          // functionからpower automateに移行したため必要なくなったのでコメントアウトします。
          // 'x-functions-key': process.env.VUE_APP_SENDMAIL_KEY,
        },
        body: JSON.stringify(this.inquiry),
      });

      this.loading = this.btnDisabled = false;
      this.$router.push('./thanks');
    } catch (error) {
      window.alert(`問い合わせの送信に失敗しました。大変申し訳ありません。
少々お時間を置きましてから再度お試しください。`);
      this.loading = this.btnDisabled = false;
    }
  }
}
