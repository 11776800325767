import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=91df21a8&"
import script from "./confirm.vue?vue&type=script&lang=ts&"
export * from "./confirm.vue?vue&type=script&lang=ts&"
import style0 from "./confirm.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/localization/pageList.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Csrc%5Cviews%5Cinquiry%5Cconfirm.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/localization/inquiry.json?vue&type=custom&index=1&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Csrc%5Cviews%5Cinquiry%5Cconfirm.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports